<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation class="loginForm">
      <v-toolbar-title>登录</v-toolbar-title>
      <v-text-field label="登录" name="login" :rules="[v => !!v || '不能为空']" v-model="form.key" prepend-icon="mdi-account" type="text" required></v-text-field>
      <v-text-field id="password" label="密码" :rules="[v => !!v || '不能为空']" v-model="form.password" name="password" prepend-icon="mdi-lock" type="password" required @keyup.enter="login"></v-text-field>
      <v-btn block color="success" style="margin: 13px 0;" :disabled="!valid" @click="login">登录</v-btn>
      <v-card-actions>
        <v-btn color="#666666" text @click="$router.replace('/password')">忘记密码？</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="#666666" text @click="$router.replace('/reg')">注册</v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>
<script>
  import { post, tips, obj, setCookie } from '@/facade'
  export default {
    name: 'login',
    data() {
      return {
        valid: true,
        form: { type: 'email' }
      }
    },
    methods: {
      async login() {
        const value = this.$refs.form.validate()
        if (value) {
          const data = await post('/user/login', this.form)
          if (data.code == 'ok') {
            obj.token = data.token;
            setCookie('token', data.token, 1);
            obj.uid = data.uid;
            setCookie('uid', data.uid, 1);
            localStorage.setItem('name', JSON.stringify(this.form.key))
            this.$router.replace('/')
          } else {
            tips('error', data.message)
          }
        }

      }
    }
  }
</script>